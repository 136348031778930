/* eslint-disable @typescript-eslint/camelcase */
const path = require('path')

module.exports = {
  siteMetadata: {
    title: 'The Dentists At Gateway Crossing',
    description:
      'THE DENTISTS AT GATEWAY CROSSING Extremely caring and friendly service. Front desk has smiling faces and Dr. Vogt understands what it takes to provide exc',
    siteUrl: 'https://thedentistsatgc.com/',
    author: '',
    twitter: '',
  },
  flags: {
    DEV_SSR: false,
    FAST_REFRESH: true,
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: `gatsby-plugin-preconnect`,
      options: {
        domains: [
          'https://www.googletagmanager.com',
          'https://www.google-analytics.com',
          'https://www.googleadservices.com',
          'https://fonts.gstatic.com',
        ],
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/posts/`,
        name: 'posts',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/images/`,
        name: 'images',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/components/`,
        name: 'components',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/types/`,
        name: 'types',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/`,
        name: 'content',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/`,
        name: 'src',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/gatsby-config.js`,
      },
    },
    `gatsby-plugin-preact`,
    `gatsby-plugin-styled-components`,
    {
      resolve: 'gatsby-plugin-root-import',
      options: {
        images: path.join(__dirname, 'src/images'),
        content: path.join(__dirname, 'content'),
        src: path.join(__dirname, 'src'),
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'The Dentists at Gateway Crossing',
        short_name: 'The Dentists at Gateway Crossing',
        description:
          'THE DENTISTS AT GATEWAY CROSSING Extremely caring and friendly service. Front desk has smiling faces and Dr. Vogt understands what it takes to provide exc',
        homepage_url: 'https://thedentistsatgc.com',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#673ab7',
        display: 'standalone',
        icons: [
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/img/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    /*{
      resolve: 'gatsby-plugin-google-analytics',
      options: {
        trackingId: '',
      },
    },*/
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    /*{
      resolve: 'gatsby-plugin-graphql-codegen',
      options: {
        fileName: `types/graphql-types.d.ts`,
      },
    },*/
    'gatsby-plugin-catch-links',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-twitter',
    'gatsby-plugin-typescript',
    'gatsby-transformer-sharp',
    `gatsby-plugin-meta-redirect`,
  ],
}
